export class Patient {

	constructor(
		public id? : string,
		public id_sk? : string,
		public firstName = "",
		public lastName = "",
		public age? :number,
		public city? : string,
		public phoneNumber? : string,
		public email? : string,
		public doctorId? : number,
		public clientId = 1,
		public companyId = 2,
		public companyIdString = "2") { }
}