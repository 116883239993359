<div class="card" *ngIf="!patient.id_sk">
  <div class="card-header">
    <h2>Self registration</h2>
  </div>
  <div class="card-body">
    <form novalidate (submit)="save()" #registrationForm="ngForm">
      <div class="form-group">
        <label for="lastname">Company</label>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="companyIdString" id="company2" value="2"
          [(ngModel)]="patient.companyIdString">
          <label class="form-check-label" for="company2">
            Diagn&oacute;stico Radiol&oacute;gico
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="companyIdString" id="company3" value="1" 
          [(ngModel)]="patient.companyIdString">
          <label class="form-check-label" for="company3">
            Dentametrics
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="companyIdString" id="company1" value="3" checked
          [(ngModel)]="patient.companyIdString">
          <label class="form-check-label" for="company1">
            HyperLife
          </label>
        </div>
      </div>
      <div class="form-group">
        <label for="name">First Name</label>
        <input type="text" id="firstName" name="firstName" class="form-control" required placeholder="First Name (Required)" 
        [(ngModel)]=patient.firstName #firstNameVar="ngModel" 
        [ngClass]="{'is-invalid' : (firstNameVar.touched || firstNameVar.dirty) && !firstNameVar.valid}"/>
        <span class="invalid-feedback">
          <span *ngIf="firstNameVar.errors?.required">Please enter your first name.</span>
        </span>
      </div>
      <div class="form-group">
        <label for="lastname">Last name</label>
        <input type="text" id="lastNameId" name="lastName" class="form-control" required placeholder="Last Name (Required)"
        [(ngModel)]=patient.lastName #lastNameVar="ngModel" 
        [ngClass]="{'is-invalid' : (lastNameVar.touched || lastNameVar.dirty) && !lastNameVar.valid}"/>
        <span class="invalid-feedback">
          <span *ngIf="lastNameVar.errors?.required">Please enter your last name.</span>
        </span>
      </div>
      <div class="form-group">
        <label for="age">Age</label>
        <input type="number" id="ageId" name="age" min="0" max="100" class="form-control" required placeholder="Age (Required)"
        [(ngModel)]=patient.age #ageVar="ngModel" 
        [ngClass]="{'is-invalid' : (ageVar.touched || ageVar.dirty) && !ageVar.valid}"/>
        <span class="invalid-feedback">
          <span *ngIf="ageVar.errors?.required">Please enter your age.</span>
        </span>
      </div>
      <div class="form-group">
        <label for="phonenumber">Phone number</label>
        <input type="tel" id="phoneNumberId" name="phoneNumber" maxlength="10" class="form-control" placeholder="Phone number"
        [(ngModel)]=patient.phoneNumber #phoneNumberVar="ngModel"/>
      </div>
      <div class="form-group">
        <label for="email">Email</label>
        <input type="email" id="emailId" name="email" class="form-control" placeholder="Email address" 
        [(ngModel)]=patient.email #emailVar="ngModel"/>
      </div>
      <div class="form-group">
        <label for="city">City</label>
        <input type="text" id="cityId" name="city" class="form-control" placeholder="City"
        [(ngModel)]=patient.city #cityVar="ngModel"/>
        <span class="invalid-feedback">
          <span *ngIf="cityVar.errors?.required">Please enter your city.</span>
        </span>
      </div>
      <div class="form-group">
        <label for="doctor">Doctor</label>
        <input id="doctor" type="text" class="form-control" [class.is-invalid]="searchFailed" [ngbTypeahead]="search"  name="doctor"
          [(ngModel)]="doctorModel" [inputFormatter]="formatter" [resultFormatter]="formatter" placeholder="Doctor" />
      </div>
      <div class="form-group">
        <label for="client">Client</label>
        <select id="inputState" id="clientId" name="clientId" class="form-control" 
        [(ngModel)]=patient.clientId #clientVar="ngModel" >
          <option [ngValue]='client.id' *ngFor='let client of clients'> {{ client.name }} </option>
        </select>
      </div>
      <div class="d-flex justify-content-center mt-5">
        <button type="submit" class="btn btn-primary mr-2" [disabled]="!registrationForm.valid">Save</button>
        <button type="button" class="btn btn-danger" (click)="reset()">Cancel</button>
        <!-- <a [routerLink]="['/home']" class="btn btn-danger">Cancel</a> -->
      </div>
    </form>
  </div>
</div>

<div *ngIf="patient.id_sk" class="card bg-success text-white text-center p-3">
  <h1 class="display-1" >Success </h1>
  <p>
    Please provide the following code to the personel at the clinic <br />
    Code: <span class="font-weight-bold">{{ patient.id_sk }}</span>
  </p>
  <div class="mt-3">
    <button type="button" class="btn btn-primary" (click)="reset()">Return</button>
  </div>
</div>