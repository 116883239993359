import { Component, OnInit } from '@angular/core';
import { CatalogService } from './catalog.service';
import { IClient } from './client';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap, switchMap, catchError } from 'rxjs/operators';
import { Patient } from './patient';

@Component({
    selector: 'sr-patient',
    templateUrl: './patient.component.html',
    providers: [ CatalogService ]
})

export class PatientComponent implements OnInit {
    private _catalogService : CatalogService;
    patient = new Patient();

    clients: IClient[];
    errorMessage: string;

    doctorModel: any;
    searching = false;
    searchFailed = false;

    constructor(private http: HttpClient) {}

    formatter = (result: {nombreCompleto: string}) => result.nombreCompleto;
    
    ngOnInit(): void {

        this._catalogService = new CatalogService(this.http);
        this._catalogService.getClients().subscribe({
            next: (clients: any[]) => this.clients = clients,
             //clients => this.clients = clients,
            error: err => this.errorMessage = err
        });
        throw new Error("Method not implemented.");
    }

    save(): void {
        this.patient.doctorId = this.doctorModel?.id;
        this._catalogService.postPatient(this.patient).subscribe({
             next: (response) => this.onSaveNext(response), 
             error: err => this.errorMessage = err
        });
    }
    onSaveNext(response: any): void {
        this.patient = response.body;
        console.log(this.patient.id_sk);
    }

    reset(): void {
        this.patient = new Patient();
    }

    search = (text$: Observable<string>) =>
        text$.pipe(
            debounceTime(300),
            distinctUntilChanged(),
            tap(() => this.searching = true),
            switchMap(name =>
                this._catalogService.searchDoctor(name).pipe(
                    tap(() => this.searchFailed = false),
                    catchError(() => {
                        this.searchFailed = true;
                        return of([]);
                    }))
            ),
            tap(() => this.searching = false)
        )
}