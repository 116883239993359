<!-- <nav class="navbar navbar-expand navbar-light bg-light">
  <ul class="nav nav-pills">
    <li><a class="nav-link" [routerLink]="['/home']">Home</a></li>
    <li><a class="nav-link" [routerLink]="['/registration']">Registration</a></li>
  </ul>
</nav> -->
<div class="container"> 
  <div class="d-flex justify-content-center">
    <div class="flex-column">
      <div class="row my-5">
        <div class="col px-md-5">
          <a href="https://dentametrics.com" target="_blank" class="mr-auto">
            <img src="../assets/images/dentametrics-logo.png" alt="Dentametrics"/>
          </a>
        </div>
        <div class="col px-md-5">
          <a href="https://diagnosticoradiologico.mx" target="_blank">
            <img src="../assets/images/diagnostico-logo.png" alt="Diagn&oacute;stico Radiol&oacute;gico"/>
          </a>
        </div>
      </div>
      <sr-patient></sr-patient>
      <!-- <router-outlet></router-outlet> -->
    </div>
  </div>
</div>