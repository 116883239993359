import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { IClient } from './client';
import { Patient } from './patient';

const PARAMS = new HttpParams({
  fromObject: {
    page: '1',
    pageLength: '10',
    showInactive: 'false'
  }
});

@Injectable()

export class CatalogService {
    private baseUrl = 'https://registro.diagnosticoradiologico.mx/api';

    constructor(private http: HttpClient) {}


    getClients() : Observable<IClient[]> {
        const url = `${this.baseUrl}/client`;
        return this.http.get<IClient[]>(url);
    }

    searchDoctor(name: string) {
        if (name === '') {
          return of([]);
        }
    
        const url = `${this.baseUrl}/doctor/search`; 
        return this.http
          .get(url, {params: PARAMS.set('searchClause', name)}).pipe(
            map(response => response)
          );
      }

    postPatient(patientForm: Patient) : Observable<Patient> {
      const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        var url = `${this.baseUrl}/patient`;
        return this.http.post<Patient>(url, patientForm, { headers, observe: 'response' } )
        .pipe(
          map((response:any) => { return response; }),
          catchError(this.handleError)
        );
    }
  handleError(handleError: any): import("rxjs").OperatorFunction<Patient, any> {
    throw new Error("Method not implemented.");
  }
}